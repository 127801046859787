.card-login {
  width: 80%;
  height: 50%;
}

@media  screen and (min-width: 650px) {
  .card-login {
    width: 40%;
    height: 50%;
  }
}


 