.cadenas__inputs-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 10px;
  /* border: 1px solid blue; */
}

.cadenas__grid-2 {
  grid-template-columns: repeat(2, 1fr);
}
.cadenas__grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

.cadenas__grid-5 {
  grid-template-columns: repeat(5, 1fr);
  gap: 3px;
}

.cadenas__blue-switch {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cadenas__blue-switch > p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #828282;
}

.cadenas__container-input-file {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  margin-bottom: 10px;
}

.cadenas__button-index {
  position: relative;
  top: -3px;
  left: -5px;
  font-size: 12px;
  font-weight: bold;
}
.card__btn-file {
  font-size: 13px;
  text-align: center;
  overflow: hidden;
}

.liquidacion__label-days {
  width: 50%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  color: #828282;
  font-weight: 600;
  text-align: center;
}
/* Si es menor igual <= 100px */
@media screen and (max-width: 710px ) {
  .cadenas__inputs-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 10px;
    overflow-x: hidden;
  }
  .cadenas__blue-switch p {
    font-size: 13px;
  }

  .card__btn-file {
    font-size: 9px !important;
    text-align: center;
    grid-column: span 2;
    width: 100% !important;
  }
}
@media screen and (max-width: 400px ) {
  .cadenas__inputs-container-doc {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-template-rows: auto;
    gap: 10px;
  }
  .card__btn-file {
    font-size: 9px !important;
    text-align: center;
    width: 100% !important;
    border: 1px solid #00609C;
  }
  
}

@media screen and (min-width: 712px) {
  .card__btn-file {
    font-size: 13px !important;
    text-align: center;
  }
}