.add__buton-float {
  align-items: center;
  justify-content: center;
  background: #00609C;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex: none;
  gap: 10px;
  height: 30px;
  padding: 10px;
  width: 30px;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
  transition: .5s ease all;
}
.add__buton-float:hover {
  background-color:#00cf69 ;
}