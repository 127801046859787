.main-box::-webkit-scrollbar{
  -webkit-appearance: none;
}

.main-box::-webkit-scrollbar-thumb{
  background-color: #e0e0e0;
}
.main-box::-webkit-scrollbar-track{
  width: 7px;
}

.main-box::-webkit-scrollbar:vertical{
  width: 7px;
}

.modal__reponsive{
  border-radius: 8px!important;
}