.MuiAccordion-root {
  border: 0 !important ;
  border-radius: 0 !important;
}

.MuiListItemButton-root.menu-item-btn {
  border-radius: 8px !important;
}

.MuiListItemButton-root.menu-item-btn.selected {
  background: #f1f1f1 !important;
}

li .menu-list-item {
  display: flex;
  align-items: center;
}
li .menu-list-item:hover {
  background: #fcfcfc;
}
li .menu-list-item.selected {
  background: #f1f1f1;
}
/* .MuiAccordion-root:hover{
  background-color: rgba(0, 0, 0, 0.01)
} */
@media (min-width: 600px) {
  .main-box .MuiPaper-root {
    border-radius: 8px !important;
  }
  .MuiPaper-root.MuiAppBar-root {
    width: calc(100% - 226px) !important;
    border-radius: 10px !important;
    margin: 8px 24px 8px 203px !important;
  }
}
