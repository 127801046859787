.dashboard-table{
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
}
.dashboard-table thead tr th, .dashboard-table tbody tr td{
    padding: 8px 4px;
    font-size: 13px;
    text-align: center;
}
.dashboard-table tbody tr td{
    /*border-top: 1px solid #f1f1f1;*/
}
.dashboard-table thead tr th{
    font-weight: 500;
    color: #00CD68;
    border-bottom: 2px solid #f1f1f1;
}
.dashboard-table tbody tr:last-child td{
    border-top: 2px solid #f1f1f1;
    font-weight: 600;
}
.dashboard-table.modal tbody tr:last-child td{
    border-top: none;
    font-weight: 400;
}

.dashboard-table.normal-footer tbody tr td{
    font-weight: 400;
    border-bottom: 2px solid #f1f1f1;
}