.escalonesTable thead tr th, .escalonesTable tbody tr td{
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.escalonesTable tbody tr:last-child td{
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.escalonesTable tbody tr.editing td{
    background: white;
}

.escalonesTable tbody tr.editing td:first-child{
    border-radius: 5px 0px 0px 5px;
}

.escalonesTable tbody tr.editing td:last-child{
    border-radius: 0 5px 5px 0;
}

.escalonesTable thead tr th .addEscalonBtn, .escalonesTable tbody tr td .addEscalonBtn{
    display: none;
}

.escalonesTable tbody tr.enabled-add-step:hover td{
    border-bottom: 2px solid #00609c;
    border-top: 2px solid #00609c;
}

.escalonesTable tbody tr.disabled-add-next-step:hover td{
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.escalonesTable thead tr th{
    border: none!important;
}

.escalonesTable thead tr:hover th .addEscalonBtn, .escalonesTable tbody tr:hover td .addEscalonBtn{
    display: inline-flex;
}

.escalonesTable tr .addEscalonBtn:hover{
    background: #00609c;
    color: "white"
}