.modal__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  text-transform: uppercase;
  color: #00609C;
}

/* Si es menor igual <= 100px */
@media screen and (max-width: 800px ) {
  .modal__reponsive {
    width: 600px !important;
  }

}

/* Si es menor igual <= 100px */
@media screen and (max-width: 710px ) {
  .modal__reponsive {
    width: 500px !important;
  }

}

/* Si es menor igual <= 100px */
@media screen and (max-width: 610px ) {
  .modal__reponsive {
    width: 475px !important;
  }

}

/* Si es menor igual <= 100px */
@media screen and (max-width: 510px ) {
  .modal__reponsive {
    width: 375px !important;
  }

}

/* Si es menor igual <= 100px */
@media screen and (max-width: 450px ) {
  .modal__reponsive {
    width: 300px !important;
  }

}

/* Si es menor igual <= 100px */
/* @media screen and (max-width: 390px ) {
  .modal__reponsive {
    width: 250px !important;
  }

} */
