.card__card-title-container {
  display: flex;
  flex-direction: row;
  height: 40px;
  margin-top: 5px;
}
.card__card-container-description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.card__icon {
  align-items: center;
  background: #00609C;
  border-radius: 6px;
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  justify-content: center;
  left: -15px;
  top: -2px;
  padding: 1px;
  position: relative;
  width: 35px;
  cursor: pointer;
}

.card__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 0px;
  text-transform: uppercase;
  color: #00609C;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.card__body-container {
  width: 95%;
  margin: 10px auto;
}

.card__icon-help-container {
  width: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #00609C;
  opacity: 0.3;
  transition: .3s ease all;
  cursor: pointer;
  margin-top: 5px;
}
.card__icon-help-container:hover {
  opacity: 0.9;
}

/* Si es menor igual <= 100px */
@media screen and (max-width: 510px ) {
  .card__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 0px;
    text-transform: uppercase;
    color: #00609C;
  }
}

/* Si mayor mayor a >= 200 */
@media  screen and (max-width: 1023px) {
  
}