body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
}

body::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.space-between {
  justify-content: space-between;
}

.center {
  align-items: center;
  justify-content: center;
}

.area-loading {
  z-index: 3;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.815);
  position: fixed;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.area-loading-modal {
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.confimacion-container {
  height: 45vh;
  display: block;
  overflow-y: auto;
  width: 100%;
  /* white-space: ; */
  margin: 10px auto;
  border-radius: 10px;
  transition: .3s ease all;
}

.confimacion-container::-webkit-scrollbar {
  -webkit-appearance: none;
}

.confimacion-container::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border-radius: 5px;
}

.confimacion-container::-webkit-scrollbar-track {
  width: 7px;
}

.confimacion-container::-webkit-scrollbar:vertical {
  width: 7px;
}

.card_confirmation-icon {
  opacity: 0.8;
  transition: 0.8s ease all;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 5px;
}

.card_confirmation-icon:hover {
  opacity: 1;
}

.card_confirmacion-container-icon {
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  overflow-x: auto;
  padding: 5px;
  scroll-behavior: smooth;
}

.card_confirmacion-container-icon::-webkit-scrollbar {
  -webkit-appearance: none;
}

.card_confirmacion-container-icon::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 5px;
  transition: .5s ease-in-out all;
}

.card_confirmacion-container-icon:hover::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
}

.card_confirmacion-container-icon::-webkit-scrollbar-track {
  height: 7px;
}

.card_confirmacion-container-icon::-webkit-scrollbar:horizontal {
  height: 7px;
}

.card_confirmacion-arrow-container {
  height: '118px';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: 'transparent';
  opacity: 0.3;
  transition: .3s ease all;
}

.card_confirmacion-arrow-container:hover {
  opacity: 0.8;
  transform: scale(1.2);
}

.opacity {
  opacity: 0.2;
  transition: .3s ease all;
}

.opacity:hover {
  opacity: 0.8;
}

.info-text_p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: .3s ease all;
}

.info-text_p:hover,  .info-text_p.noHidden{
  white-space: inherit;
}

.rdg-cell{
  padding-inline: 0px;
}

.rdg-header-row .rdg-cell{
  padding-inline: 8px;
}